/* eslint-disable camelcase */
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Select from 'react-select'

import styled from 'styled-components'

import { IoCloseSharp } from 'react-icons/io5'

import { GET_SKILLS } from '../../../../store/types/skills'

import { languages } from '../../../../constant/languages'

import {
  SET_CERTIFICATION,
  SET_EDUCATION,
  SET_EXPERIENCE,
  SET_HARDWARE_REQUIREMENT,
  SET_IS_CERTIFICATION,
  SET_IS_EDUCATION,
  SET_IS_EXPERIENCE,
  SET_IS_HARDWARE,
  SET_IS_LANGUAGE,
  SET_IS_PERSONAL_SKILLS,
  SET_IS_SHIFT,
  SET_IS_SOFTWARE,
  SET_LANGUAGE,
  SET_PERSONAL_SKILLS,
  SET_REACH_STEP,
  SET_SHIFT_AVAILABILITY,
  SET_SOFTWARE,
  SET_TECHNICAL_SKILLS,
  SWITCH_STEP,
} from '../../../../store/types/jobPosting'

import { H2, P } from '../../../../style/Typography.style'
import Flex from '../../../../components/layouts/Flex'
import DashboardButton from '../../../../components/dashboard/DashboardButton'
import AddSectionButton from '../../../../components/dashboard/controls/AddSectionButton'
import ExperienceInput from '../../../../components/dashboard/jobPosting/ExperienceInput'
import EducationalLevel from '../../../../components/dashboard/jobPosting/EducationalLevel'
import HardWareRequirement from '../../../../components/dashboard/jobPosting/HardWareRequirement'
import Certification from '../../../../components/dashboard/jobPosting/Certification'
import Shift from '../../../../components/dashboard/jobPosting/Shift'
import Software from '../../../../components/dashboard/jobPosting/Software'

import { ToastContainer, toast } from 'react-toastify'
import {
  setAvailableToStart,
  setIsAvalibleToStart,
} from '../../../../store/actions/jobPostingActions'
import AvailableToStart from './AvailableToStart'

import axios from 'axios'

import { baseUrl } from '../../../../constant/constant'

const Qualification = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    isEducation,
    isLanguage,
    isShift,
    isExperience,
    isCertification,
    isSoftware,
    isHardware,
    isSkill,
    personal_skills,
    language,
    experience,
    technical_skills,
    isPersonalSkills,
    software,
    education,
    hardware_requirement,
    shift_availability,
    certification,
    isAvalableToStart,
    availability_to_start,
  } = useSelector((state) => state.jobPosting)

  const personalSkills = useSelector((state) => state.skills.personalSkills)
  const softSkills = useSelector((state) => state.skills.technicalSkills)

  const [newPersonalSkills, setNewPersonalSkills] = React.useState([])
  const [newSoftSkills, setNewSoftSkills] = React.useState([])

  const handleCancelJobPost = (e) => {
    navigate('/job-listing')
  }

  // handle first experience years input
  const handleExperienceYearOne = (e) => {
    const cloneExperience = [...experience]
    cloneExperience[0] = {
      years: e.target.value,
      specification: cloneExperience[0].specification || '',
    }

    dispatch({ type: SET_EXPERIENCE, payload: cloneExperience })
  }

  // handle first experience specification input
  const handleExperienceSpecificationOne = (e) => {
    const cloneExperience = [...experience]
    cloneExperience[0] = {
      years: cloneExperience[0].years,
      specification: e.target.value,
    }

    dispatch({ type: SET_EXPERIENCE, payload: cloneExperience })
  }

  // handle first experience years input
  const handleExperienceYearTwo = (e) => {
    const cloneExperience = [...experience]
    cloneExperience[1] = {
      years: e.target.value,
      specification: cloneExperience[1].specification || '',
    }

    dispatch({ type: SET_EXPERIENCE, payload: cloneExperience })
  }

  // handle first experience specification input
  const handleExperienceSpecificationTwo = (e) => {
    const cloneExperience = [...experience]
    cloneExperience[1] = {
      years: cloneExperience[1].years || '',
      specification: e.target.value,
    }

    dispatch({ type: SET_EXPERIENCE, payload: cloneExperience })
  }

  async function loadSkills() {
    setNewPersonalSkills([])
    setNewSoftSkills([])
    dispatch({
      type: GET_SKILLS,
      payload: {
        personalSkills: [],
        technicalSkills: [],
      },
    })

    axios
      .get(`${baseUrl}/technical-skills?all=true`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('recruiterToken')}`,
        },
      })
      .then((res) => {
        const data = res.data.resp
        if (data) {
          const skills = data.map((x) => x.skills)
          dispatch({
            type: GET_SKILLS,
            payload: {
              personalSkills: [],
              technicalSkills: skills,
            },
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    loadSkills()
  }, [])

  useMemo(() => {
    if (personalSkills.length !== 0) {
      personalSkills.map((skill) =>
        setNewPersonalSkills((prevState) => [
          ...prevState,
          { value: skill, label: skill },
        ])
      )
    }

    if (softSkills.length !== 0) {
      const skills = softSkills.map((skill) => {
        return {
          value: skill,
          label: skill,
        }
      })
      setNewSoftSkills(skills)
    }
  }, [personalSkills, softSkills, languages])

  const newLanguages = useMemo(() => {
    const updatedLanguages = []

    languages.forEach((lang) => {
      updatedLanguages.push({ value: lang.name, label: lang.name })
    })
    return updatedLanguages
  }, [languages])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (experience[0].specification === '')
      return toast('Please enter experience specialization', { type: 'error' })
    if (experience[0].years === '')
      return toast('Please enter experience years', { type: 'error' })

    if (technical_skills.length === 0)
      return toast('Please select technical skills', { type: 'error' })

    dispatch({ type: SWITCH_STEP, payload: 4 })
    dispatch({ type: SET_REACH_STEP, payload: 4 })
    localStorage.setItem('reachedStep', 4)
    localStorage.setItem('step', 4)
    localStorage.setItem(
      'qualifications',
      JSON.stringify({
        isEducation,
        isLanguage,
        isShift,
        isExperience,
        isCertification,
        isSoftware,
        isHardware,
        isSkill,
        personal_skills,
        language,
        experience,
        technical_skills,
        isPersonalSkills,
        software,
        education,
        hardware_requirement,
        shift_availability,
        certification,
        availability_to_start,
      })
    )
  }

  return (
    <Flex
      margin="2.5rem 1rem 9rem 1rem"
      direction="column"
      width="100%"
      maxw="500px"
      maxwMobile="300px"
    >
      <H2>Qualifications</H2>

      <ExperienceInput
        onChangeExperience={handleExperienceYearOne}
        onChangeSpecification={handleExperienceSpecificationOne}
        years={experience[0].years}
        specification={experience[0].specification}
      />

      <MultiSelectWrapper style={{ zIndex: 4 }}>
        <label>Required Technical Skills</label>
        <Select
          placeholder="Press enter to add more..."
          className="slider"
          options={newSoftSkills}
          isMulti
          value={technical_skills}
          onChange={(value) =>
            dispatch({ type: SET_TECHNICAL_SKILLS, payload: value })
          }
        />
      </MultiSelectWrapper>

      {/* Availability to Start */}
      {isAvalableToStart && (
        <Flex margin="1.5rem 0 0 0" width="100%" position="relative">
          <MultiSelectWrapper>
            <label>Availability to Start</label>

            <AvailableToStart />
          </MultiSelectWrapper>
          <Close
            bottom="-16%"
            onClick={() => {
              dispatch(setAvailableToStart(''))
              dispatch(setIsAvalibleToStart(!isAvalableToStart))
            }}
          >
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* add Educational Level */}
      {isEducation && (
        <Flex margin=".5rem 0 0 0" width="100%" position="relative">
          <EducationalLevel />
          <Close
            onClick={() => {
              dispatch({ type: SET_EDUCATION, payload: '' })
              dispatch({ type: SET_IS_EDUCATION })
            }}
          >
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* Experience added */}
      {isExperience && (
        <Flex margin=".5rem 0 0 0" width="100%" position="relative">
          <ExperienceInput
            onChangeExperience={handleExperienceYearTwo}
            onChangeSpecification={handleExperienceSpecificationTwo}
            years={experience[1].years}
            specification={experience[1].specification}
          />
          <Close
            onClick={() => {
              dispatch({
                type: SET_EXPERIENCE,
                payload: [
                  {
                    years: '',
                    specification: '',
                  },
                  {
                    years: '',
                    specification: '',
                  },
                ],
              })
              dispatch({ type: SET_IS_EXPERIENCE })
            }}
          >
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* Hardware Requirements added */}
      {isHardware && (
        <Flex margin=".5rem 0 0 0" width="100%" position="relative">
          <HardWareRequirement data={hardware_requirement} />
          <Close
            onClick={() => {
              dispatch({
                type: SET_HARDWARE_REQUIREMENT,
                payload: hardware_requirement,
              })
              dispatch({ type: SET_IS_HARDWARE })
            }}
          >
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* Languages added */}
      {isLanguage && (
        <Flex width="100%" position="relative">
          <MultiSelectWrapper>
            <label>Language</label>
            <Select
              placeholder="Press enter to add more..."
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              className="slider"
              options={newLanguages}
              value={language}
              onChange={(value) =>
                dispatch({ type: SET_LANGUAGE, payload: value })
              }
              isMulti
            />
          </MultiSelectWrapper>
          <Close
            bottom="-16%"
            onClick={() => {
              dispatch({ type: SET_LANGUAGE, payload: [] })
              dispatch({ type: SET_IS_LANGUAGE })
            }}
          >
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* Certification added */}
      {isCertification && (
        <Flex margin="1.5rem 0 0 0" width="100%" position="relative">
          <Certification />
          <Close
            onClick={() => {
              dispatch({ type: SET_CERTIFICATION, payload: '' })
              dispatch({ type: SET_IS_CERTIFICATION })
            }}
          >
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}
      {/* Personal Skills added */}
      {isPersonalSkills && (
        <Flex width="100%" position="relative">
          <MultiSelectWrapper>
            <label>Personal Skills</label>
            <Select
              placeholder="Press enter to add more..."
              className="slider"
              options={newPersonalSkills}
              value={personal_skills}
              onChange={(value) =>
                dispatch({ type: SET_PERSONAL_SKILLS, payload: value })
              }
              isMulti
            />
          </MultiSelectWrapper>
          <Close
            bottom="-16%"
            onClick={() => {
              dispatch({ type: SET_PERSONAL_SKILLS, payload: [] })
              dispatch({ type: SET_IS_PERSONAL_SKILLS })
            }}
          >
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* Sift added */}
      {isShift && (
        <Flex margin="1.5rem 0 0 0" width="100%" position="relative">
          <Shift />
          <Close
            onClick={() => {
              dispatch({ type: SET_SHIFT_AVAILABILITY, payload: '' })
              dispatch({ type: SET_IS_SHIFT })
            }}
          >
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* Software added */}
      {isSoftware && (
        <Flex margin=".5rem 0 0 0" width="100%" position="relative">
          <Software />
          <Close
            bottom="-16%"
            onClick={() => {
              dispatch({ type: SET_SOFTWARE, payload: '' })
              dispatch({ type: SET_IS_SOFTWARE })
            }}
          >
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* More Qualifications */}
      <Flex width="100%" margin="1.5rem 0" direction="column">
        <P size="0.75rem" weight="700">
          More Qualifications
        </P>

        <Grid>
          <AddSectionButton
            onClick={() => dispatch(setIsAvalibleToStart(!isAvalableToStart))}
            text="Availability to Start"
            active={isAvalableToStart}
          />
          <AddSectionButton
            onClick={() => dispatch({ type: SET_IS_EDUCATION })}
            text="Education"
            active={isEducation}
          />
          <AddSectionButton
            onClick={() => dispatch({ type: SET_IS_LANGUAGE })}
            text="Language"
            active={isLanguage}
          />
          <AddSectionButton
            onClick={() => dispatch({ type: SET_IS_SHIFT })}
            text="Shift Preference"
            active={isShift}
          />
          <AddSectionButton
            onClick={() => dispatch({ type: SET_IS_CERTIFICATION })}
            text="License/Certification"
            active={isCertification}
          />
          <AddSectionButton
            onClick={() => dispatch({ type: SET_IS_SOFTWARE })}
            text="Software"
            active={isSoftware}
          />
          <AddSectionButton
            onClick={() => {
              dispatch({
                type: SET_HARDWARE_REQUIREMENT,
                payload: hardware_requirement,
              })
              dispatch({ type: SET_IS_HARDWARE })
            }}
            text="Hardware Requirements"
            active={isHardware}
          />
          <AddSectionButton
            onClick={() => dispatch({ type: SET_IS_PERSONAL_SKILLS })}
            text="Personal Skills"
            active={isPersonalSkills}
          />
        </Grid>
      </Flex>

      {/*  submit button */}
      <Flex margin="1rem 0 0 0" width="100%" justify="flex-end">
        <DashboardButton
          text="Cancel"
          onClick={handleCancelJobPost}
          rounded
          notIcon
          marginRight={'5px'}
        />
        <DashboardButton text="Next" onClick={handleSubmit} rounded notIcon />
      </Flex>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Flex>
  )
}

export default Qualification

export const MultiSelectWrapper = styled.div`
  width: ${({ width }) => width || '100%'};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.75rem;

  label {
    font-size: 0.75rem;
    color: #31374f;
    font-weight: 300;
  }

  .slider {
    width: 100%;

    .css-1s2u09g-control {
      border: 1px solid #aeb3c9;
      background-color: #fff;
      border-radius: 0;
      /* padding: 1rem; */
      font-size: 0.75rem;
      font-weight: 300;
    }

    .css-1pahdxg-control {
      border: 1px solid #1a1a1a;
      box-shadow: 0 0 0 1px #1a1a1a;
      border-radius: 0;
      font-size: 0.75rem;
      font-weight: 300;
    }

    .css-1rhbuit-multiValue {
      background-color: #f2f3f8;
      border-radius: 3px;
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
  row-gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const Close = styled.div`
  position: absolute;
  right: -3rem;
  bottom: ${({ bottom }) => bottom || '10%'};
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  .icon {
    font-size: 1rem;
    color: #858aa0;
  }

  &:hover {
    background-color: #eeeef6;
  }
`
