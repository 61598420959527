import React from 'react'

import styled from 'styled-components'

import Flex from '../../../../components/layouts/Flex'

const GroupWrapper = ({ title, children }) => {
  return (
    <>
      <StyledTitle>{title}</StyledTitle>
      <Flex direction="column" border="1px solid #AEB3C9" padding="1rem">
        {children}
      </Flex>
    </>
  )
}

const StyledTitle = styled.h5`
  margin: 0.5rem;
  font-weight: 300;
  font-size: 1.5rem;
`

export default GroupWrapper
