import React, { useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import Flex from '../../../../components/layouts/Flex'
import { SingleDropdownSelect } from '../../../../components/dashboard/TalentPool/DropdownSelect'

import {
  setModal,
  selectRecruiter,
  showApplicants,
} from '../../../../store/actions/listsActions'
import { setRecruiters } from '../../../../store/actions/recruitersActions'
import { updateTalents } from '../../../../store/actions/talentActions'

import useMutation from '../../../../hooks/useMutation'
import useFetch from '../../../../hooks/useFetch'

const InviteToInterview = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { applicant, applicants, recruiter, moveList, selectedList } =
    useSelector((state) => state.lists)
  const { talents, talent } = useSelector((state) => state.talents)
  const { recruiters } = useSelector((state) => state.requisitions)

  const { data: recruitersData } = useFetch('/recruiters')

  const { data, loading, success, error, handleRequest } = useMutation()

  function handleSelectRecruiter(e) {
    const idx = e.target.selectedIndex
    const option = e.target.querySelectorAll('option')[idx]
    const selectedRecruiter = {
      id: e.target.value,
      calendar_url: option.getAttribute('data-calendarUrl'),
    }
    dispatch(selectRecruiter(selectedRecruiter))
  }

  function handleSubmitInterview() {
    if (!recruiter.id) {
      return addToast('Recruiter field is required.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    handleRequest('/schedule-interview', {
      applicant_id: applicant.applicant_id.toString(),
      role: selectedList.list_name,
      list_id: applicant.list_id.toString(),
      interview_date: `${moment().format('YYYY-MM-DD')}T00:00:59.999Z`,
      interview_time: `${moment().format('YYYY-MM-DD')}T00:00:59.999Z`,
      recruiter_id: recruiter.id.toString(),
      stages: 'first_interview',
    })
  }

  // update recruiter
  useEffect(() => {
    if (recruitersData) {
      dispatch(setRecruiters(recruitersData?.resp))
    }
  }, [recruitersData])

  useEffect(() => {
    if (data) {
      const filteredApplicants = applicants.filter(
        (user) => user.applicant_id !== applicant.applicantId
      )

      const filteredTalents = talents.filter(
        (talent) => talent.id !== applicant.applicantId
      )

      const selectedTalent = talent
      selectedTalent.listName = moveList.list_name

      const changedList = selectedList
      const updatedList = moveList

      changedList.numbersOfApplicantInList -= 1
      updatedList.numbersOfApplicantInList += 1

      dispatch(updateTalents([selectedTalent, ...filteredTalents]))
      dispatch(showApplicants(filteredApplicants))

      dispatch(setModal(''))
      addToast(
        `Applicant ${applicant.applicantName} has been scheduled for an interview.`,
        {
          appearance: 'success',
          autoDismiss: true,
        }
      )
    }
  }, [success])

  //   if request is unsuccessful
  useEffect(() => {
    if (error) {
      if (
        error?.response?.data?.message?.trim() ===
        `Applicant with id: ${applicant?.applicantId} has been schedulled for an interview`
      ) {
        dispatch(setModal(''))
        addToast(
          `Applicant ${applicant.applicantName} has been scheduled for an interview.`,
          {
            appearance: 'success',
            autoDismiss: true,
          }
        )
      } else {
        addToast(error?.response.data?.message || 'Something went wrong', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }
  }, [error])

  return (
    <>
      <MainModal
        title={`Invite ${applicant.applicantName} for 1st Interview`}
        buttonText="Add"
        loading={loading}
        onClick={handleSubmitInterview}
      >
        <Flex direction="column" gap="0.4rem" margin="1rem 0 0 0">
          <SingleDropdownSelect
            onChange={(e) => {
              handleSelectRecruiter(e)
            }}
            title="Recruiter"
          >
            <option>Choose an option</option>
            {recruiters?.map((recruiter) => {
              if (recruiter?.Recruiter?.name) {
                return (
                  <option
                    key={recruiter?.Recruiter?.id}
                    value={recruiter?.Recruiter?.id}
                    data-recruiterName={recruiter?.Recruiter?.name}
                    data-calendarEmail={recruiter?.Recruiter.calendar_email}
                    data-calendarUrl={recruiter?.Recruiter.calendar_url}
                  >
                    {recruiter?.Recruiter?.name}
                  </option>
                )
              }
              return null
            })}
          </SingleDropdownSelect>
        </Flex>
      </MainModal>
    </>
  )
}

export default InviteToInterview
