import requisitionType from '../types/requisition'

const initialState = {
  modal: '',
  interviews: [],
  applicants: [],
  filters: {
    startDate: '',
    endDate: '',
    search: '',
    searchRole: '',
    sort: 'DESC',
    vacancy: '',
  },
  selectedInterview: {},
  recruiters: [],
  selectedStage: 'applicant_screening',
  modalFooter: false,
  applicantListUrl: '',
  refreshList: '',
  updatedNote: null,
  bulkItems: {
    applicant_screening: {
      selectedAll: false,
      checkedItems: [],
      uncheckedItems: [],
      selectedItems: 0,
      filters: {},
    },
    first_interview: {
      selectedAll: false,
      checkedItems: [],
      uncheckedItems: [],
      selectedItems: 0,
      filters: {},
    },
    second_interview: {
      selectedAll: false,
      checkedItems: [],
      uncheckedItems: [],
      selectedItems: 0,
      filters: {},
    },
    Skill_assesment: {
      selectedAll: false,
      checkedItems: [],
      uncheckedItems: [],
      selectedItems: 0,
      filters: {},
    },
    client_profiling: {
      selectedAll: false,
      checkedItems: [],
      uncheckedItems: [],
      selectedItems: 0,
      filters: {},
    },
    client_interview: {
      selectedAll: false,
      checkedItems: [],
      uncheckedItems: [],
      selectedItems: 0,
      filters: {},
    },
    offer: {
      selectedAll: false,
      checkedItems: [],
      uncheckedItems: [],
      selectedItems: 0,
      filters: {},
    },
  },
  bulkAction: '',
  changedInterviews: [],
}

const recruiterReducer = (state = initialState, action) => {
  switch (action.type) {
    case requisitionType.SETMODAL:
      return {
        ...state,
        modal: action.payload,
      }
    case requisitionType.SETINTERVIEWS:
      return {
        ...state,
        interviews: action.payload,
      }
    case requisitionType.SETAPPLICANTS:
      return {
        ...state,
        applicants: action.payload,
      }
    case requisitionType.SETFILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }
    case requisitionType.CLEARFILTERS:
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
      }
    case requisitionType.SETUPDATEDSTAGE:
      return {
        ...state,
        updatedStage: action.payload,
      }
    case requisitionType.SETSELECTEDINTERVIEW:
      return {
        ...state,
        selectedInterview: action.payload,
      }
    case requisitionType.SETRECRUITERS:
      return {
        ...state,
        recruiters: action.payload,
      }
    case requisitionType.SETSTAGE:
      return {
        ...state,
        selectedStage: action.payload,
      }
    case requisitionType.SETMODALFOOTER:
      return {
        ...state,
        modalFooter: action.payload,
      }
    case requisitionType.SETAPPLICANTLISTURL:
      return {
        ...state,
        applicantListUrl: action.payload,
      }
    case requisitionType.REFRESHLIST:
      return {
        ...state,
        refreshList: action.payload,
      }
    case requisitionType.SETUPDATEDNOTE:
      return {
        ...state,
        updatedNote: action.payload,
      }
    case requisitionType.SET_BULK_ITEMS:
      return {
        ...state,
        bulkItems: {
          ...state.bulkItems,
          [action.stage]: {
            ...state.bulkItems[action.stage],
            ...action.payload,
          },
        },
      }
    case requisitionType.SET_BULK_ACTION:
      return {
        ...state,
        bulkAction: action.payload,
      }
    case requisitionType.SET_CHANGED_INTERVIEWS:
      return {
        ...state,
        changedInterviews: action.payload,
      }
    default:
      return state
  }
}

export default recruiterReducer
