import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

import { H2, P } from '../style/Typography.style'
import AuthLayout from '../layouts/AuthLayout'
import { Input, PasswordInput } from '../components/Input'
import { Button } from '../components/Button'
import Error from '../components/Error'

import { login } from '../store/actions/authActions'
import { clearErrors } from '../store/actions/errorActions'

const Login = ({ login, token, clearErrors, error, isLoading }) => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showError, setShowError] = useState(false)

  const msg = typeof error.msg === 'object' ? null : error.msg
  const recruiterToken = localStorage.getItem('recruiterToken')

  useEffect(() => {
    if (recruiterToken) {
      navigate('/')
    }
  }, [recruiterToken])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (email !== '' || password !== '') {
      login({ email, password })
    }

    if (token) {
      navigate('/')
    }
  }

  useEffect(() => {
    if (error.msg === 'Update your password') {
      navigate('/update-password')
      clearErrors()
    } else if (error.msg && error.msg !== '') {
      setShowError(true)
    }
  }, [error])

  return (
    <AuthLayout>
      <LoginContainer onSubmit={handleSubmit}>
        <H2>Recruiter’s Login</H2>
        {showError && msg !== '' && <Error error={msg && msg} />}
        <Input
          value={email}
          error={error !== ''}
          onChange={(value) => setEmail(value)}
          title="Email"
        />

        <PasswordInput
          error={error !== ''}
          value={password}
          onChange={(value) => setPassword(value)}
          title="Password"
          noValidation
        />

        <Button onClick={handleSubmit} type="submit">
          {isLoading ? 'Loading...' : 'Login'}
        </Button>

        <P
          style={{ cursor: 'pointer', color: '#1877F2' }}
          textAlign="center"
          marginTop="1.5rem"
          onClick={() => navigate('/forget-password')}
        >
          Forgot your password?
        </P>
      </LoginContainer>
    </AuthLayout>
  )
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  error: state.error,
  isLoading: state.auth.isLoading,
})

export default connect(mapStateToProps, { login, clearErrors })(Login)

Login.propTypes = {
  isLoading: propTypes.bool,
  error: propTypes.object.isRequired,
  login: propTypes.func.isRequired,
  clearErrors: propTypes.func.isRequired,
}

export const LoginContainer = styled.form`
  width: 100%;

  .lockWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .lock {
      margin: 0 auto;
      margin-bottom: 2.5rem;
    }
  }

  p {
    font-size: 0.875rem;
    font-weight: 400;
    color: #31374f;
    text-align: center;

    &.mt-3 {
      margin-top: 2rem;
    }

    .iconLink {
      color: #1877f2;
      text-decoration: none;
    }
  }

  .linkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    .forgetLink {
      text-decoration: none;
      font-size: 0.75rem;
      font-weight: 400;
      color: #fd2055;
      text-align: center;
    }
  }
`
