import axios from 'axios'
import { baseUrl } from '../../constant/constant'

import axiosInstance from '../../services/config/AxiosIntance'

import { countTruthyPropertiesOfObject } from '../../utils/utils'

import {
  FETCH_TALENTS_SUCCESS,
  FETCH_TALENTS_ERROR,
  FETCH_TALENTS_LOADING,
  SELECT_TALENT,
  FETCH_PROFESSIONAL_HEADLINE_SUCCESS,
  FETCH_SKILLS_SUCCESS,
  SET_PROFESSIONAL_HEADLINES_FILTER,
  FETCH_TALENT_BY_FILTER_SUCCESS,
  SET_SKILL_FILTER,
  SET_SECOND_FILTER,
  SET_FIRST_FILTER,
  SET_LEVEL_OF_EDUCATION_FILTER,
  SET_CHANNEL,
  SET_COUNTRY_FILTER,
  SET_IMAGE_FILTER,
  SET_FILTER_BY_YEAR,
  SET_SOFT_SKILLS,
  FETCH_PERSONAL_SKILLS_SUCCESS,
  SET_MORE_JOB_TITLE,
  SET_HARDWARE_REQUIREMENT,
  FETCH_PERSONAL_SKILLS_LOADING,
  SET_FILTER_QUERY_TERM,
  FETCH_POPULAR_PROFESSIONAL_HEADLINES_SUCCESS,
  SORT_TALENTS,
  ADD_TALENT_CV,
  GET_TALENT_BY_ID,
  UPDATE_TALENTS,
  TOGGLE_IS_PROFESSIONAL,
  TOGGLE_IS_SKILL,
  TOGGLE_IS_EXPERIENCE,
  UPDATE_PAGINATION,
  SET_GLOBAL_SEARCH_TERM,
  SET_SELECTED_HARDWARE,
  SET_SELECTED_PING,
  SET_SELECTED_TALENT_DISPLAY,
  SET_SELECTED_DOWNLOADMBPS,
  SET_SELECTED_UPLOADMBPS,
  SET_ALL_OF_THESE_WORDS,
  SET_COMPANY_NAME,
  SET_OS,
  SET_PROCESSOR,
  SET_RAM,
  TOGGLE_SIDE_FILTERS,
  INCREASE_ACTIVE_FILTER,
  SET_BULK_ACTION,
  SET_BULK_ITEMS,
} from '../actions/types'

// Get all talents
export const fetchTalents = () => (dispatch, getState) => {
  dispatch({ type: FETCH_TALENTS_LOADING })

  const token = getState().auth.token
  const page = getState().talents.pagination.page

  axios
    .get(`${baseUrl}/applicants`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: page ? page + 1 : 1,
      },
    })
    .then((res) => {
      const data = {
        talents: res.data.applicants,
        pagination: res.data.pagination,
      }
      dispatch({
        type: FETCH_TALENTS_SUCCESS,
        payload: data,
      })
    })
    .catch((err) => {
      dispatch({
        type: FETCH_TALENTS_ERROR,
        payload: err.response.data || err.response.data.message,
      })
    })
}

export const updateTalents = (talents) => {
  return {
    type: UPDATE_TALENTS,
    payload: talents,
  }
}

export const updatePagination = (pagination) => {
  return {
    type: UPDATE_PAGINATION,
    payload: pagination,
  }
}

// sort talents
export const sortTalents = (talents) => (dispatch) => {
  dispatch({
    type: SORT_TALENTS,
    payload: talents,
  })
}

// get talent
export const getTalent = (id) => (dispatch) => {
  axiosInstance
    .get(`/applicants?applicantId=${id}`)
    .then((res) => {
      dispatch({
        type: SELECT_TALENT,
        payload: res.data.applicants[0],
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

// set talent
export const setTalent = (payload) => {
  return {
    type: SELECT_TALENT,
    payload: payload,
  }
}

// set global search term\
export const setFilterQueryTerm = (term) => {
  return {
    type: SET_GLOBAL_SEARCH_TERM,
    payload: term,
  }
}

export const setSelectedHardware = (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_SELECTED_HARDWARE,
    payload: filter,
  })
}

export const setSelectedPing = (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_SELECTED_PING,
    payload: filter,
  })
}

export const setTalentsDisplay = (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_SELECTED_TALENT_DISPLAY,
    payload: filter,
  })
}

export const setDownloadMBPS = (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_SELECTED_DOWNLOADMBPS,
    payload: filter,
  })
}

export const setuploadMBPS = (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_SELECTED_UPLOADMBPS,
    payload: filter,
  })
}

export const setAllOfTheseWords = (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_ALL_OF_THESE_WORDS,
    payload: filter,
  })
}

// Filter Talents
export const filterTalents = (isNextPage) => (dispatch, getState) => {
  const {
    professionalHeadlinesFilters,
    secondFilter,
    skillsFilters,
    levelOfEducation,
    channelId,
    talentsDisplay,
    country,
    hasPhoto,
    filterByYears,
    queryTerm,
    jobTitle,
    hardwareRequirement,
    firstFilter,
    globalSearchTerm,
    selectedPing,
    uploadMBPS,
    downloadMBPS,
    selectedHardware,
    allOfTheseWords,
    companyName,
    selectedSoftSkills,
    os,
    processor,
    ram,
    sortOrder,
  } = getState().talents
  const token = getState().auth.token
  dispatch({ type: FETCH_TALENTS_LOADING })

  let location = {}

  if (secondFilter) {
    location.city = secondFilter
  }

  if (country) {
    location.country = country
  }

  if (country && secondFilter) {
    location = { country, city: secondFilter }
  }

  const filterParams = {
    hasPhoto: hasPhoto,
    AllOfTheseWords: allOfTheseWords,
    selectedPing: selectedPing,
    downloadMBPS: downloadMBPS,
    uploadMBPS: uploadMBPS,
    professionalHeadline: JSON.stringify(professionalHeadlinesFilters),
    jobTitle: JSON.stringify(jobTitle),
    skills:
      firstFilter !== ''
        ? JSON.stringify(firstFilter)
        : JSON.stringify(skillsFilters),
    levelOfEdu: levelOfEducation,
    channelId: channelId,
    talentsDisplay: JSON.stringify(talentsDisplay),
    hardwareReq: hardwareRequirement,
    yearsOfExperience: filterByYears.length
      ? JSON.stringify(filterByYears)
      : '',
    profSummary: queryTerm,
    page: isNextPage || 1,
    globalSerchTerm: globalSearchTerm.replaceAll('"', ''),
    selectedhardwarereq: selectedHardware,
    companyName: companyName,
    softSkills: JSON.stringify(selectedSoftSkills),
    os: JSON.stringify(os),
    processor: JSON.stringify(processor),
    ram: JSON.stringify(ram),
    country: country,
    city: secondFilter !== '' && JSON.stringify(secondFilter),
    sortOrder: sortOrder,
    fromTalentPool: true,
  }

  axios
    .get(`${baseUrl}/applicants`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: filterParams,
    })
    .then((res) => {
      const data = {
        talents: res.data.applicants,
        pagination: res.data.pagination,
      }
      dispatch({
        type: FETCH_TALENT_BY_FILTER_SUCCESS,
        payload: data,
      })
      dispatch({
        type: SET_BULK_ITEMS,
        payload: { filters: filterParams },
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

// Get Professional Headlines
export const fetchProfessionalHeadlines = () => (dispatch, getState) => {
  const token = getState().auth.token

  axios
    .get(`${baseUrl}/professional-headlines?withSkills=true`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: FETCH_PROFESSIONAL_HEADLINE_SUCCESS,
        payload: res.data.resp,
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

// get popular professional headlines
export const fetchPopularProfessionalHeadlines = () => (dispatch, getState) => {
  const token = getState().auth.token

  axios
    .get(`${baseUrl}/applicants/professional-headlines?withSkills=true`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: FETCH_POPULAR_PROFESSIONAL_HEADLINES_SUCCESS,
        payload: res.data.data,
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

// Get Skills
export const fetchSkills = (id) => (dispatch, getState) => {
  const token = getState().auth.token

  axios
    .get(`${baseUrl}/technical-skills?professionalHeadline_id=[${id}]`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: FETCH_SKILLS_SUCCESS,
        payload: res.data.resp,
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

// get personal skills
export const fetchPersonalSkills = () => (dispatch, getState) => {
  const token = getState().auth.token

  dispatch({
    type: FETCH_PERSONAL_SKILLS_LOADING,
  })

  axios
    .get(`${baseUrl}/skills?soft-skills`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: FETCH_PERSONAL_SKILLS_SUCCESS,
        payload: res.data.data.softSkills,
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

// Set Professional Headlines Filter
export const setProfessionalHeadlinesFilter =
  (filters) => (dispatch, getState) => {
    dispatch({
      type: SET_PROFESSIONAL_HEADLINES_FILTER,
      payload: filters,
    })
  }

// Set Soft Skills Filter
export const setSoftSkillsFilter = (filter) => {
  return {
    type: SET_SOFT_SKILLS,
    payload: filter,
  }
}

// set Filter By Photo value
export const setFilterByPhoto = (toggle) => (dispatch, getState) => {
  const { hasPhoto } = getState().talents

  dispatch({
    type: SET_IMAGE_FILTER,
    payload: toggle === false ? toggle : !hasPhoto,
  })
}

// Set Skills Filter
export const setSkillsFilter = (filters) => (dispatch, getState) => {
  dispatch({
    type: SET_SKILL_FILTER,
    payload: filters,
  })
}

export const setFirstFilter = (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_FIRST_FILTER,
    payload: filter,
  })
}

export const setSecondFilter = (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_SECOND_FILTER,
    payload: filter,
  })
}

export const setLevelOfEducation = (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_LEVEL_OF_EDUCATION_FILTER,
    payload: filter,
  })
}

export const setChannel = (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_CHANNEL,
    payload: filter,
  })
}

export const setQueryTerm = (queryTerm) => (dispatch, getState) => {
  dispatch({
    type: SET_FILTER_QUERY_TERM,
    payload: queryTerm,
  })
}

export const setJobTitle = (jobTitle) => (dispatch, getState) => {
  dispatch({
    type: SET_MORE_JOB_TITLE,
    payload: jobTitle,
  })
}

export const setHardwareRequirement = (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_HARDWARE_REQUIREMENT,
    payload: filter,
  })
}

export const setCountryFilter = (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_COUNTRY_FILTER,
    payload: filter,
  })
}

export const setFilterByYears = (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_FILTER_BY_YEAR,
    payload: filter,
  })
}

export const addCv = (cv) => {
  return {
    type: ADD_TALENT_CV,
    payload: cv,
  }
}

export const selectTalent = (id) => {
  return {
    type: GET_TALENT_BY_ID,
    payload: id,
  }
}

export const toggleProfessional = (state) => {
  return {
    type: TOGGLE_IS_PROFESSIONAL,
    payload: state,
  }
}

export const toggleSkill = (state) => {
  return {
    type: TOGGLE_IS_SKILL,
    payload: state,
  }
}
export const toggleYearsOfExperience = (state) => {
  return {
    type: TOGGLE_IS_EXPERIENCE,
    payload: state,
  }
}
export const setCompanyName = (company) => {
  return {
    type: SET_COMPANY_NAME,
    payload: company,
  }
}
export const setOS = (os) => {
  return {
    type: SET_OS,
    payload: os,
  }
}
export const setProcessor = (processor) => {
  return {
    type: SET_PROCESSOR,
    payload: processor,
  }
}
export const setRAM = (ram) => {
  return {
    type: SET_RAM,
    payload: ram,
  }
}
export const toggleSideFilters = () => {
  return {
    type: TOGGLE_SIDE_FILTERS,
  }
}
export const calculateActiveFilters = () => (dispatch, getState) => {
  const talentState = getState().talents

  const talentDisplayFilter = ['talentsDisplay']
  dispatch({
    type: INCREASE_ACTIVE_FILTER,
    payload: {
      prop: 'talentsDisplay',
      amount: countTruthyPropertiesOfObject(talentState, talentDisplayFilter),
    },
  })

  const findProfileFilters = [
    'hasPhoto',
    'allOfTheseWords',
    'selectedPing',
    'downloadMBPS',
    'downloadMBPS',
  ]
  dispatch({
    type: INCREASE_ACTIVE_FILTER,
    payload: {
      prop: 'findProfile',
      amount: countTruthyPropertiesOfObject(talentState, findProfileFilters),
    },
  })
  const workExperienceFilters = ['jobTitle', 'companyName', 'filterByYears']
  dispatch({
    type: INCREASE_ACTIVE_FILTER,
    payload: {
      prop: 'workExperience',
      amount: countTruthyPropertiesOfObject(talentState, workExperienceFilters),
    },
  })

  const skillFilters = [
    'professionalHeadlinesFilters',
    'skillsFilters',
    'selectedSoftSkills',
  ]
  dispatch({
    type: INCREASE_ACTIVE_FILTER,
    payload: {
      prop: 'skills',
      amount: countTruthyPropertiesOfObject(talentState, skillFilters),
    },
  })

  const educationFilters = ['levelOfEducation']
  dispatch({
    type: INCREASE_ACTIVE_FILTER,
    payload: {
      prop: 'education',
      amount: countTruthyPropertiesOfObject(talentState, educationFilters),
    },
  })

  const channelFilters = ['channelId']
  dispatch({
    type: INCREASE_ACTIVE_FILTER,
    payload: {
      prop: 'channelId',
      amount: countTruthyPropertiesOfObject(talentState, channelFilters),
    },
  })

  const hardwareFilters = ['os', 'processor', 'ram']
  dispatch({
    type: INCREASE_ACTIVE_FILTER,
    payload: {
      prop: 'hardware',
      amount: countTruthyPropertiesOfObject(talentState, hardwareFilters),
    },
  })

  const locationFilters = ['country', 'secondFilter']
  dispatch({
    type: INCREASE_ACTIVE_FILTER,
    payload: {
      prop: 'location',
      amount: countTruthyPropertiesOfObject(talentState, locationFilters),
    },
  })
}

export const setBulkAction = (payload) => {
  return {
    type: SET_BULK_ACTION,
    payload: payload,
  }
}

export const setBulkItems = (payload) => {
  return {
    type: SET_BULK_ITEMS,
    payload: payload,
  }
}
