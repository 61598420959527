import React from 'react'

import MainModal from '../modal/MainModal'
import InfoModalHead from '../modal/InfoModalHead'

import { P } from '../../../style/Typography.style'

import { useDispatch } from 'react-redux'

import { setModal } from '../../../store/actions/listsActions'

import { SET_SHOW_CONFIRM_LEAVE_MODAL } from '../../../store/actions/types'

const ConfirmLeaveModal = () => {
  const dispatch = useDispatch()

  return (
    <MainModal
      isOpen={true}
      buttonText="Leave"
      error
      onClick={() => dispatch(setModal(''))}
      onClose={() =>
        dispatch({ type: SET_SHOW_CONFIRM_LEAVE_MODAL, payload: false })
      }
      header={<InfoModalHead title="You have unsaved changes on skills tab" />}
    >
      <P>
        It looks like you have made some changes in the skills tab. Do you want
        to save these changes before leaving?
      </P>
    </MainModal>
  )
}

export default ConfirmLeaveModal
