import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import axiosInstance from '../../../../services/config/AxiosIntance'

import styled from 'styled-components'

import Flex from '../../../../components/layouts/Flex'
import UpcomingInterview from './UpcomingInterview'
import HistoryInterview from './HistoryInterview'

const InterviewTableWrapper = () => {
  const [phase, setPhase] = useState('upcoming')
  const [upcoming, setUpcoming] = useState(0)
  const [history, setHistory] = useState(0)

  const { filters } = useSelector((state) => state.dashboard)

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const companyDetails =
    role === 'poc' && JSON.parse(currentUser)?.companyDetails

  const queryParams = new URLSearchParams(filters).toString()
  let url = `/dashboard/client-interviews-counts?${queryParams}`

  if (role === 'poc') {
    url += `&companyDetails=${JSON.stringify(companyDetails)}`
  }

  const fetchInterviewCounts = useCallback(async (url) => {
    const result = await axiosInstance.get(url)
    const { data } = result

    setUpcoming(data?.result?.interviews?.upcoming)
    setHistory(data?.result?.interviews?.history)
  })

  useEffect(() => {
    fetchInterviewCounts(url)
  }, [filters])

  useEffect(() => {
    fetchInterviewCounts(url)
  }, [])

  return (
    <StyledContainer>
      <Flex padding="0.75rem 1rem" items="center" gap="2.5rem">
        <div
          onClick={() => {
            setPhase('upcoming')
          }}
          className={phase === 'upcoming' ? 'tab active' : 'tab'}
        >
          Upcoming&nbsp;({upcoming})
        </div>
        <div
          onClick={() => {
            setPhase('history')
          }}
          className={phase === 'history' ? 'tab active' : 'tab'}
        >
          History&nbsp;({history})
        </div>
      </Flex>

      {phase === 'upcoming' && <UpcomingInterview />}
      {phase === 'history' && <HistoryInterview />}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 16rem);
  background: #fff;
  border: 0.5px solid #aeb3c9;
  border-bottom: none;
  margin-top: 1rem;
  overflow-y: hidden;

  .tab {
    font-size: 1rem;
    font-weight: 300;
    color: #676e92;
    cursor: pointer;

    &:hover,
    &.active {
      font-weight: 500;
      color: #31374f;
      border-bottom: 1px solid #1877f2;
    }
  }
`

export default InterviewTableWrapper
