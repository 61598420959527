import React, { useEffect, useState, useCallback } from 'react'

import { useSelector } from 'react-redux'

import moment from 'moment'
import { useToasts } from 'react-toast-notifications'

import styled from 'styled-components'

import Table from '../../../../components/Table'
import { P } from '../../../../style/Typography.style'
import TableTag from '../../TableTag'

import Flex from '../../../../components/layouts/Flex'

import axiosInstance from '../../../../services/config/AxiosIntance'

const HistoryInterview = () => {
  const { addToast } = useToasts()

  const [interviews, setInterviews] = useState([])
  const [totalPage, setTotalPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)
  const [rowCount, setRowCount] = useState(0)

  const { filters } = useSelector((state) => state.dashboard)

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const companyDetails =
    role === 'poc' && JSON.parse(currentUser)?.companyDetails

  const queryParams = new URLSearchParams(filters).toString()
  let url = `/dashboard/client-interviews?stage=history&${queryParams}`

  if (role === 'poc') {
    url += `&companyDetails=${JSON.stringify(companyDetails)}`
  }

  const fetchData = useCallback(async (url) => {
    setLoading(true)
    try {
      const result = await axiosInstance.get(url)
      const { data } = result

      setTotalPage(data?.result?.pagination?.pageCount || 1)
      setCurrentPage(data?.result?.pagination?.page || 1)
      setPageSize(data?.result?.pagination?.pageSize || 10)
      setRowCount(data?.result?.pagination?.rowCount || 0)

      setInterviews(data?.result?.interviews || [])
      setLoading(false)
    } catch (err) {
      addToast(err?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
      })
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchData(url)
  }, [filters])

  useEffect(() => {
    fetchData(url)
  }, [])

  const getInterviewStatus = (item) => {
    if (item) {
      if (item?.interviewStatus === 'Rejected') {
        if (item?.rejectionType) {
          return `Rejected - ${item?.rejectionType}`
        } else {
          return 'Rejected'
        }
      } else {
        return item?.interviewStatus
      }
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Applicant Name',
        accessor: 'applicantName',
      },
      {
        Header: 'Interview Status',
        accessor: 'interviewStatus',
        Cell: ({ row }) => {
          if (row?.original?.interviewStatus === 'Accepted') {
            return <TableTag status="approved" content="Accepted" />
          } else if (
            row?.original?.interviewStatus === 'In_progress' ||
            !row?.original?.interviewStatus
          ) {
            return <TableTag status="pending" content="In Progress" />
          } else {
            return (
              <TableTag
                status="rejected"
                content={row?.original?.interviewStatus}
              />
            )
          }
        },
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Last Updated Date',
        hidden: 'true',
        Cell: ({ row }) => {
          return (
            <>
              <p>{row?.original?.updatedDate}</p>
              <p>{row?.original?.updatedTime}</p>
            </>
          )
        },
      },
    ],
    []
  )

  const tableData = interviews.map((item) => ({
    applicantName: item.applicantName || '',
    interviewStatus: getInterviewStatus(item) || '',
    role: item.role,
    updatedDate: moment(item.updatedAt).format('MMMM DD, YYYY'),
    updatedTime: moment(item.updatedAt).format('hh:mm a'),
  }))

  return (
    <StyledWrapper>
      {tableData.length > 0 ? (
        <Table
          data={tableData}
          columns={columns}
          loading={loading}
          totalPage={totalPage}
          currentPage={currentPage}
          pageSize={pageSize}
          rowCount={rowCount}
          fetchData={fetchData}
          setNewPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          url={`${url}&pageNumber=${currentPage}&pageSize=${pageSize}`}
          noData="Offer page is empty"
          height="calc(100vh - 20rem)"
        />
      ) : (
        <Flex
          height="calc(100vh - 15rem)"
          bg="#fff"
          justify="center"
          items="center"
        >
          <P>History is empty</P>
        </Flex>
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
`

export default HistoryInterview
