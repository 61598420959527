import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { FiCalendar, FiClock } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'
import SelectComp from '../../../../components/dashboard/controls/Select'
import DashboardInput from '../../../../components/dashboard/DashboardInput'
import MainModal from '../../../../components/dashboard/modal/MainModal'
import Flex from '../../../../components/layouts/Flex'
import phases from '../../../../constant/interviewPhase'
import useFetch from '../../../../hooks/useFetch'
import axiosInstance from '../../../../services/config/AxiosIntance'
import { P } from '../../../../style/Typography.style'
import { DateTimeWrapper } from './UpdateStage'

const AddSchedule = () => {
  const { addToast } = useToasts()
  const [options, setOptions] = useState([])
  const [recruitersOptions, setRecruitersOptions] = useState([])
  const [applicantsOptions, setApplicantsOptions] = useState([])

  const { recruiters } = useSelector((state) => state.requisitions)
  const [interviewDate, setInterviewDate] = useState(new Date())
  const [interviewTime, setInterviewTime] = useState('')
  const [role, setRole] = useState('')

  const { data, error } = useFetch('/list-names')

  // update lists names
  useEffect(() => {
    if (data) {
      data?.resp.map((option) =>
        setOptions((prevState) => [
          ...prevState,
          { value: option.id, label: option.list_name },
        ])
      )
    }
  }, [data])

  // update recruiters data
  useEffect(() => {
    if (recruiters) {
      recruiters.map((recruiter) =>
        setRecruitersOptions((prevState) => [
          ...prevState,
          {
            value: recruiter.Recruiters[0].id,
            label: recruiter.Recruiters[0].name || 'No Name',
          },
        ])
      )
    }
  }, [recruiters])

  // update applicants data
  function fetchApplicants(url) {
    const data = []
    axiosInstance.get(url).then((res) => {
      res.data.resp.applicantList.forEach((option) => {
        data.push({ value: option.applicant_id, label: option.applicant_name })
      })
    })

    return data
  }

  // update applicant from selected list
  function handleSelectList(e) {
    let url
    if (e.target.value === 'All') {
      url = '/applicant-list'
    } else {
      url = `/applicant-list/?listId=${e.target.value}`
    }

    const data = fetchApplicants(url)
    setApplicantsOptions(data)
  }

  useEffect(() => {
    const data = fetchApplicants('/applicant-list')
    setApplicantsOptions(data)
  }, [])

  useEffect(() => {
    if (error) {
      addToast(error.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [error])

  return (
    <>
      <MainModal
        title="Add Applicant"
        buttonText="Add"
      >
        <TwoGrid>
          <Flex
            position="static"
            direction="column"
            gap="0.4rem"
            margin="0 0 1rem 0"
          >
            <P size="0.85rem">List</P>
            <SelectComp
              height="2.5rem"
              options={options}
              onchange={(e) => handleSelectList(e)}
              firstOption={{ value: 'All', label: 'All Lists' }}
            />
          </Flex>

          <Flex zIndex="1" direction="column" gap="0.4rem" margin="0 0 1rem 0">
            <P size="0.85rem">Applicant</P>
            <StyledSelect
              className="basic-single"
              classNamePrefix="select"
              defaultValue={applicantsOptions[0]}
              isLoading={false}
              isClearable={true}
              isSearchable={true}
              name="applicants"
              options={applicantsOptions}
            />
          </Flex>
        </TwoGrid>

        <DashboardInput
          name="role"
          label="Role"
          handleChange={(e) => setRole(e.target.value)}
          value={role}
          height="2.5rem"
        />

        <Flex
          position="static"
          direction="column"
          gap="0.4rem"
          margin="0 0 1rem 0"
        >
          <P size="0.85rem">Phase</P>

          <SelectComp height="2.5rem" options={phases} />
        </Flex>

        <DateTimeWrapper>
          <P size="0.85rem">Interview Date</P>
          <FiCalendar className="datetime-icon" />
          <DatePicker
            className="date-picker"
            selected={interviewDate}
            onChange={(date) => setInterviewDate(date)}
          />
        </DateTimeWrapper>

        <DateTimeWrapper className="mt">
          <P size="0.85rem">Interview Time</P>
          <FiClock className="datetime-icon" />
          <DatePicker
            className="date-picker"
            selected={interviewTime}
            onChange={(date) => setInterviewTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </DateTimeWrapper>

        <Flex direction="column" gap="0.4rem" margin="1rem 0 0 0">
          <P size="0.85rem">Recruiter</P>

          <SelectComp
            height="2.5rem"
            options={recruitersOptions}
            firstOption={{ value: '', label: 'Select Recruiter' }}
          />
        </Flex>
      </MainModal>
    </>
  )
}

export default AddSchedule

const TwoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`

const StyledSelect = styled(Select)`
  width: 100%;
  height: 2.5rem;
  position: relative;
  z-index: 1000;

  .select__menu {
    z-index: 1000;
  }

  .select__control {
    height: 100%;
    border-radius: 0;
    border: 1px solid #aeb3c9;
  }
`
