import React, { useEffect } from 'react'

import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import DownloadCVModal from '../../../components/cv/DownloadCVModal'
import Heading from '../../../components/dashboard/Heading'
import ApplicantDetailsModal from '../../../components/dashboard/TalentPool/ApplicantDetailsModal'
import CreateList from './components/CreateList'
import DeleteList from './components/DeleteList'
import EditList from './components/EditList'
import HeaderFilter from './components/HeaderFilter'
import AssignSkillTest from './components/AssignSkillTest'
import InviteToInterview from './components/InviteToInterview'
import ListActivities from './components/ListActivities'
import ListsWrapper from './components/ListsWrapper'
import MoveApplicant from './components/MoveApplicant'
import RejectApplicant from './components/RejectApplicant'
import RemoveFromList from './components/RemoveFromList'
import UpdateScore from './components/UpdateScore'

import { updateTalents } from '../../../store/actions/talentActions'
import { setModalFooter } from '../../../store/actions/recruitersActions'

import useFetch from '../../../hooks/useFetch'

const Lists = () => {
  const dispatch = useDispatch()
  const { modal } = useSelector((state) => state.lists)

  const { data } = useFetch(`/applicants`)

  useEffect(() => {
    dispatch(setModalFooter(false))
    if (data) {
      dispatch(updateTalents(data.applicants))
    }
  }, [data])

  return (
    <>
      {/* lists heading */}
      <Heading title="Lists" subTitle={moment().format('MMMM Do, YYYY')} />
      {/* lists filter, it contains the search filter and add list button */}
      <HeaderFilter />
      {/* below is this lists and applicants attached to each lists */}
      <ListsWrapper />
      {/* modal section */}
      {modal === 'createList' && <CreateList />}
      {modal === 'updateList' && <EditList />}
      {modal === 'deleteList' && <DeleteList />}
      {modal === 'listActivities' && <ListActivities />}
      {modal === 'removeFromList' && <RemoveFromList />}
      {modal === 'moveToList' && <MoveApplicant />}
      {modal === 'assignSkillTest' && <AssignSkillTest />}
      {modal === 'inviteToInterview' && <InviteToInterview />}
      {modal === 'viewListActivities' && <ApplicantDetailsModal />}
      {modal === 'rejectApplicant' && <RejectApplicant />}
      {modal === 'updateScore' && <UpdateScore />}

      {/* talent */}
      <DownloadCVModal />
    </>
  )
}

export default Lists
