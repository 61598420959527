import React, { useEffect, useState, useCallback } from 'react'

import { useSelector } from 'react-redux'

import moment from 'moment'
import { useToasts } from 'react-toast-notifications'

import styled from 'styled-components'

import Table from '../../../../components/Table'
import { P } from '../../../../style/Typography.style'

import Flex from '../../../../components/layouts/Flex'

import axiosInstance from '../../../../services/config/AxiosIntance'

const UpcomingInterview = () => {
  const { addToast } = useToasts()

  const [interviews, setInterviews] = useState([])
  const [totalPage, setTotalPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)
  const [rowCount, setRowCount] = useState(0)

  const { filters } = useSelector((state) => state.dashboard)

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const companyDetails =
    role === 'poc' && JSON.parse(currentUser)?.companyDetails

  const queryParams = new URLSearchParams(filters).toString()
  let url = `/dashboard/client-interviews?stage=upcoming&${queryParams}`

  if (role === 'poc') {
    url += `&companyDetails=${JSON.stringify(companyDetails)}`
  }

  const fetchData = useCallback(async (url) => {
    setLoading(true)
    try {
      const result = await axiosInstance.get(url)
      const { data } = result

      setTotalPage(data?.result?.pagination?.pageCount || 1)
      setCurrentPage(data?.result?.pagination?.page || 1)
      setPageSize(data?.result?.pagination?.pageSize || 10)
      setRowCount(data?.result?.pagination?.rowCount || 0)

      setInterviews(data?.result?.interviews || [])
      setLoading(false)
    } catch (err) {
      addToast(err?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
      })
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchData(url)
  }, [filters])

  useEffect(() => {
    fetchData(url)
  }, [])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Applicant Name',
        accessor: 'applicantName',
      },
      {
        Header: 'Schedule',
        accessor: 'schedule',
        hidden: 'true',
        Cell: ({ row }) => {
          let schedule = (
            <>
              <p>{row?.original?.interviewDate}</p>
              <p>{row?.original?.interviewTime}</p>
            </>
          )
          if (row?.original?.interviewDate === 'Invalid date') {
            schedule = 'No schedule found'
          }
          return schedule
        },
      },
      {
        Header: 'Interview Link',
        accessor: 'link',
        hidden: 'true',
        Cell: ({ row }) => {
          const meetUrl = row?.original?.link
          let content = (
            <StyledURLRow href={meetUrl} rel="noreferrer" target="_blank">
              {meetUrl}
            </StyledURLRow>
          )
          if (!meetUrl) {
            content = 'No interview link found'
          }

          return content
        },
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
    ],
    []
  )

  const tableData = interviews.map((item) => ({
    applicantName: item.applicantName || '',
    interviewDate: moment(item.start_time).format('MMMM DD, YYYY'),
    interviewTime:
      moment(item.start_time).format('hh:mm a') +
      ' - ' +
      moment(item.end_time).format('hh:mm a'),
    link: item.link,
    role: item.role,
    updatedDate: moment(item.updatedAt).format('MMMM DD, YYYY'),
    updatedTime: moment(item.updatedAt).format('hh:mm a'),
  }))

  return (
    <StyledWrapper>
      {tableData.length > 0 ? (
        <Table
          data={tableData}
          columns={columns}
          loading={loading}
          totalPage={totalPage}
          currentPage={currentPage}
          pageSize={pageSize}
          rowCount={rowCount}
          fetchData={fetchData}
          setNewPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          url={`${url}&pageNumber=${currentPage}&pageSize=${pageSize}`}
          noData="Offer page is empty"
          height="calc(100vh - 20rem)"
        />
      ) : (
        <Flex
          height="calc(100vh - 15rem)"
          bg="#fff"
          justify="center"
          items="center"
        >
          <P>Upcoming interview is empty</P>
        </Flex>
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
`
const StyledURLRow = styled.a`
  text-decoration: none;
  color: inherit;
  font-weight: 500;
  font-size: 14px;
`

export default UpcomingInterview
