export const markText = (inputString, wordToMark) => {
  if (wordToMark && inputString) {
    const escapedWord = wordToMark.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const regex = new RegExp(escapedWord, 'gi')
    const markedString = inputString.replace(
      regex,
      (match) => `<mark>${match}</mark>`
    )
    return markedString
  }
  return inputString
}

export const countTruthyPropertiesOfObject = (state, arr) => {
  return arr.reduce((count, property) => {
    if (
      Object.prototype.hasOwnProperty.call(state, property) &&
      state[property] != null &&
      state[property] !== '' &&
      state[property] !== 0 &&
      state[property] !== false &&
      state[property]?.length > 0
    ) {
      return count + 1
    } else {
      return count
    }
  }, 0)
}

export const isValidJson = (str) => {
  if (str) {
    try {
      JSON.parse(str)
      return true
    } catch (e) {
      return false
    }
  }
}

export const getCVImage = (applicant) => {
  return applicant?.image_url || applicant?.cv_image_url
}

export const generateRandomLightColor = () => {
  return 'hsl(' + Math.random() * 360 + ', 100%, 75%)'
}

export const parseLocalStorageJSON = (key) => {
  if (!key || typeof key !== 'string') {
    throw new Error('Invalid key')
  }

  /**
   * Handle non-string value with JSON.parse.
   * Catch string value and return it
   */
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch {
    return localStorage.getItem(key)
  }
}

// Reference: https://www.30secondsofcode.org/js/s/deep-equality-comparison/
export const equals = (a, b) => {
  if (a === b) return true

  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()

  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
    return a === b

  if (a.prototype !== b.prototype) return false

  const keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) return false

  return keys.every((k) => equals(a[k], b[k]))
}

/**
 * Adds the `fl_attachment` flag and custom file name to a Cloudinary URL for forced file download.
 *
 * @param {string} url - The Cloudinary URL to modify.
 * @param {string} [fileName] - The custom file name to use for the download (without extension).
 * @returns {string} - The modified URL with the `fl_attachment` flag and custom file name, or the original if the pattern doesn't match.
 *
 * @example
 * addAttachmentFlagToCloudinaryUrl('https://res.cloudinary.com/dmenvc1mv/image/upload/v1728114591/pzc5mcr4qbin8qchebuu.pdf', 'MY_CUSTOM_NAME');
 * // Output: 'https://res.cloudinary.com/dmenvc1mv/image/upload/fl_attachment:MY_CUSTOM_NAME/v1728114591/pzc5mcr4qbin8qchebuu.pdf'
 */
export const addAttachmentFlagToCloudinaryUrl = (url, fileName) => {
  const cloudinaryPattern =
    /^(https:\/\/res\.cloudinary\.com\/[\w-]+\/)(image|video|raw)\/upload\/(.*?\/)(.*)/

  if (cloudinaryPattern.test(url)) {
    // Replace the upload segment with the attachment flag and custom file name
    return url.replace(/(\/upload\/)(.*?)(\/)/, `$1fl_attachment:${fileName}$3`)
  } else {
    return url // Return the original URL if it's not a recognized Cloudinary pattern
  }
}

/**
 * Creates a debounced version of a given function, which delays its execution
 * until after a specified time has passed since the last time it was invoked.
 *
 * @param {Function} fn - The function to debounce.
 * @param {number} [ms=0] - The delay in milliseconds. Default is 0.
 * @returns {Function} - A debounced version of the input function.
 */
export const debounce = (fn, ms = 0) => {
  let timeoutId

  return function (...args) {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  }
}
