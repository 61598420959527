import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'

import { setModal } from '../../../../store/actions/emailTemplateActions'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import Flex from '../../../../components/layouts/Flex'
import DashboardInput from '../../../../components/dashboard/DashboardInput'

import useMutation from '../../../../hooks/useMutation'

import { AiFillInfoCircle } from 'react-icons/ai'
import styled from 'styled-components'

const SendEmail = () => {
  const { addToast } = useToasts()
  const { selectedEmailTemplate } = useSelector((state) => state.emailTemplates)

  const dispatch = useDispatch()
  const { loading, error, success, handleRequest } = useMutation()

  const [value, setValue] = useState('')

  useEffect(() => {
    if (success) {
      addToast(`Successfully sent email for ${value}`, {
        appearance: 'success',
        autoDismiss: true,
      })
      dispatch(setModal(''))
      setValue('')
    }
  }, [success])

  useEffect(() => {
    if (error) {
      addToast(error?.response?.data?.message || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [error])

  const handleClose = () => {
    dispatch(setModal(''))
  }

  const handleSendEmail = () => {
    if (value.trim() === '')
      return addToast('Email Address is required.', {
        appearance: 'error',
        autoDismiss: true,
      })

    const requestSample = {
      id: selectedEmailTemplate.id,
      email: value,
    }
    handleRequest(`/email-template/send-test`, requestSample)
  }

  return (
    <MainModal
      title="Send Email"
      onClick={handleSendEmail}
      onClose={handleClose}
      buttonText="Send Email"
      loading={loading}
    >
      <StyledP>
        <AiFillInfoCircle className="info-icon" />
        Template Name: {selectedEmailTemplate.name}
      </StyledP>

      <Flex gap="0.4rem">
        <DashboardInput
          name="email"
          label="Email Address"
          placeholder=""
          type="text"
          height="2.5rem"
          value={value}
          handleChange={(e) => setValue(e.target.value)}
        />
      </Flex>
    </MainModal>
  )
}

export default SendEmail

const StyledP = styled.p`
  position: relative;
  bottom: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  margin: auto;

  .info-icon {
    margin-right: 8.33px;
    margin-bottom: 2px;
    font-size: 1.2rem;
    color: #1877f2;
  }
`
