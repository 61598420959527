import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router'
import { useNavigate } from 'react-router-dom'

import Heading from '../../../components/dashboard/Heading'
import JobGridLayout from './components/JobGridLayout'
import TotalJobs from './components/TotalJobs'
import DashboardButton from '../../../components//dashboard/DashboardButton'
import { FiPlus } from 'react-icons/fi'

import Loader from '../../../components/Loader'
import JobListingDetails from './JobListingDetails'
import JobListingEmptyDetails from './JobListingEmptyDetails'

import axiosInstance from '../../../services/config/AxiosIntance'

const JobListingHome = () => {
  const [loading, setLoading] = useState(false)
  const [totalJobs, setTotalJobs] = useState(0)

  const navigate = useNavigate()
  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const userId = JSON.parse(currentUser)?.id
  const recruiterAssistant = !!JSON.parse(currentUser)?.recruiter_assistant

  const companyDetails =
    role === 'poc' && JSON.parse(currentUser)?.companyDetails

  const { selectedJob } = useSelector((state) => state.jobListing)

  const handleClientModal = () => {
    navigate('/job-posting/create')
  }

  useEffect(() => {
    setLoading(false)

    const queryParams = {
      status: 'active',
      aggregate: { column: 'id', type: 'COUNT' },
    }

    if (!recruiterAssistant && role === 'recruiter') {
      queryParams.posted_by = userId
    } else if (role === 'poc') {
      queryParams.companyDetails = JSON.stringify(companyDetails)
    }

    axiosInstance
      .get('/jobs', {
        params: queryParams,
      })
      .then((data) => {
        setTotalJobs(data.data.data.jobs[0]['count(`id`)'])
        setLoading(false)
      })
  }, [selectedJob])

  return loading ? (
    <Loader />
  ) : (
    <>
      {/* job lists heading */}
      <Heading
        title="Job Listing"
        subTitle={moment().format('MMMM Do, YYYY')}
        link="/"
      />

      {/* Content */}
      <JobGridLayout>
        <TotalJobs aggregatedValue={totalJobs} />
        <DashboardButton
            text="Create new job post"
            type="button"
            notIcon
            externalIcon={<FiPlus className="icon" />}
            maxWidth="180px"
            onClick={handleClientModal}
            float='right'
          />        
        <Routes>
          <Route path="/" element={<JobListingEmptyDetails />} />
          <Route path="/:id" element={<JobListingDetails />} />
        </Routes>
      </JobGridLayout>
    </>
  )
}

export default JobListingHome
