import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useToasts } from 'react-toast-notifications'

import { P } from '../../../../style/Typography.style'

import Flex from '../../../../components/layouts/Flex'
import Table from '../../../../components/Table'
import TableAction from './TableAction'
import TableTag from '../../../dashboard/TableTag'

import axiosInstance from '../../../../services/config/AxiosIntance'

import {
  setModal,
  showApplicants,
  selectApplicantList,
  setPagination,
} from '../../../../store/actions/listsActions'
import { getTalent } from '../../../../store/actions/talentActions'

const ListApplicants = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { selectedList } = useSelector((state) => state.lists)
  const [loading, setLoading] = useState(true)

  const { applicants, pagination } = useSelector((state) => state.lists)

  const fetchData = useCallback(async (url) => {
    setLoading(true)
    try {
      const result = await axiosInstance.get(url)

      const { data } = result

      dispatch(setPagination(data?.resp?.pagination))
      dispatch(showApplicants(data?.resp?.applicantList || []))
      setLoading(false)
    } catch (err) {
      addToast(err.response.data.message || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: true,
      })
      setLoading(false)
    }
  }, [])

  const handlePagination = (option) => {
    if ('page' in option) {
      dispatch(
        setPagination({
          ...pagination,
          pageNumber: option.page,
        })
      )
    }
    if ('pageSize' in option) {
      dispatch(
        setPagination({
          ...pagination,
          pageSize: option.pageSize,
        })
      )
    }
  }

  useEffect(() => {
    if (selectedList && selectedList.id) {
      const url = `/applicant-list?listId=${selectedList.id}&pageNumber=1&pageSize=10`
      fetchData(url)
    }
  }, [selectedList])

  const columns = useMemo(
    () => [
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ value }) => <TableAction value={value} />,
      },
      {
        Header: 'Applicant Name',
        accessor: 'applicantName',
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'Contact No.',
        accessor: 'contact',
      },
      {
        Header: 'Score',
        accessor: 'score',
        Cell: ({ row }) => {
          const high = 66
          const medium = 33
          if (row?.original?.score > high) {
            return <TableTag status="approved" content={row?.original?.score} />
          } else if (row?.original?.score > medium) {
            return <TableTag status="pending" content={row?.original?.score} />
          } else {
            return <TableTag status="rejected" content={row?.original?.score} />
          }
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => {
          if (row?.original?.status === 'Added') {
            return (
              <TableTag status="approved" content={row?.original?.status} />
            )
          } else {
            return <TableTag status="pending" content={row?.original?.status} />
          }
        },
      },
      {
        Header: 'Stage',
        accessor: 'stagePriority',
        Cell: ({ row }) => {
          return row?.original?.stage
        },
      },
      {
        Header: 'Added By',
        accessor: 'addedBy',
      },
      {
        Header: 'Date Added',
        accessor: 'dateAdded',
      },
    ],
    []
  )

  const tableData = applicants.map((item) => ({
    actions: {
      ...item,
      applicantId: item?.applicant_id,
      id: item?.applicant_id,
      applicant_list_id: item.applicant_list_id,
      listId: selectedList?.id,
      applicantName: item?.applicant_name,
      listName: selectedList?.list_name,
    },
    id: item.applicant_list_id,
    applicantName: item?.applicant_name,
    email: item?.email,
    contact: item?.phone_number,
    score: item?.score,
    status: item?.status_name,
    stage: item?.stage_name,
    stagePriority: item?.stage_priority,
    addedBy: item?.added_by,
    dateAdded: moment(item?.created_at).format('DD/MM/YYYY'),
  }))

  return (
    <>
      {applicants.length > 0 ? (
        <Table
          data={tableData}
          columns={columns}
          loading={loading}
          totalPage={pagination.pageCount}
          currentPage={pagination.pageNumber}
          pageSize={pagination.pageSize}
          rowCount={pagination.rowCount}
          fetchData={fetchData}
          setNewPageSize={(e) => handlePagination({ pageSize: e })}
          setCurrentPage={(e) => handlePagination({ page: e })}
          url={`/applicant-list?listId=${selectedList?.id}&pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}`}
          noData="Applicant list is empty"
          onClick={(row) => {
            dispatch(getTalent(row?.id))
            dispatch(setModal('viewListActivities'))
            dispatch(selectApplicantList(row))
          }}
        />
      ) : (
        <Flex
          height="calc(100vh - 15rem)"
          bg="#fff"
          justify="center"
          items="center"
        >
          <P>Applicant list is empty.</P>
        </Flex>
      )}
    </>
  )
}

export default ListApplicants
