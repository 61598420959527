import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../DropdownSelect'

import { setChannel } from '../../../../../store/actions/talentActions'
import useFetch from '../../../../../hooks/useFetch'

const ChannelFilter = () => {
  const dispatch = useDispatch()
  const { data: channels } = useFetch('/channels')
  const channelValues = channels?.data.data
  const { channelId } = useSelector((state) => state.talents)

  return (
    <>
      <SingleDropdownSelect
        name="channels"
        title="Channel"
        value={channelId}
        onChange={(e) => dispatch(setChannel(e.target.value))}
      >
        <option>Choose Option</option>
        {channelValues &&
          channelValues.length > 0 &&
          channelValues.map((option, index) => (
            <option key={index} value={option.id}>
              {option.channel}
            </option>
          ))}
      </SingleDropdownSelect>
    </>
  )
}

export default ChannelFilter
