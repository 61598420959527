import React, { memo, useState, useEffect } from 'react'

import { useSelector } from 'react-redux'

import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip,
  Title,
} from 'chart.js'
import { useToasts } from 'react-toast-notifications'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import NoData from './NoData'
import Loader from '../../../../components/Loader'
import DashboardItemTitle from './DashboardItemTitle'

import axiosInstance from '../../../../services/config/AxiosIntance'

import { chartThreeMonthColors } from '../../../../constant/constant'

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip, Title)

const RecruitmentFunnelsChart = () => {
  const { addToast } = useToasts()

  const filters = useSelector((state) => state.dashboard.filters)

  const [loading, setLoading] = useState(false)
  const [highestNumber, setHighestNumber] = useState(0)
  const [chartData, setChartData] = useState({
    labels: [''],
    datasets: [],
  })

  useEffect(() => {
    setLoading(true)
    const queryParams = new URLSearchParams(filters).toString()
    axiosInstance
      .get(`/dashboard/recruitmentChannels?${queryParams}`)
      .then((res) => {
        setChartData((prev) => ({
          ...prev,
          labels: res.data.result.labels,
          datasets: res?.data?.result?.datasets.map((item, index) => ({
            label: item.name,
            data: item.data,
            backgroundColor: chartThreeMonthColors[index],
            borderColor: 'black',
            borderWidth: 1,
            barPercentage: 1,
          })),
        }))
        const mergedArray = res?.data?.result?.datasets
          .map((dataset) => {
            return dataset.data
          })
          .reduce((acc, val) => acc.concat(val), [])
        setHighestNumber(Math.max(...mergedArray))
      })
      .catch((err) => {
        addToast(
          err?.response?.message ||
          err?.response?.data?.message ||
          'Internal Server Error',
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filters])

  useEffect(() => {
    if (chartData.datasets.length) {
      const selectedItem = chartData.datasets.map((e) => {
        if (e.stage_id === filters.stage_id) {
          return {
            ...e,
            borderColor: 'red',
          }
        } else {
          return {
            ...e,
            borderColor: 'black',
          }
        }
      })

      setChartData((prev) => ({
        ...prev,
        datasets: selectedItem,
      }))
    }
  }, [filters.stage_id])

  const options = {
    type: 'bar',
    data: chartData,
    maintainAspectRatio: false,
    plugins: {
      title: {
        color: 'black',
        display: true,
        align: 'start',
        position: 'top',
        text: 'Recruitment Channels',
        font: {
          size: 24,
          family: "'Lato', 'sans-serif'",
          weight: 300,
        },
      },
      datalabels: {
        display: true,
        color: (ctx) => ctx.dataset.backgroundColor,
        anchor: 'end',
        align: 'end',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        max: (ctx) => {
          if (highestNumber > 0) {
            return highestNumber + 10
          } else {
            return ctx.chart.scales.y.max + 10
          }
        },
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
  }

  const component = loading ? (
    <Loader />
  ) : chartData.labels.length ? (
    <Bar
      data={chartData}
      options={options}
      plugins={[ChartDataLabels]}
      width={50}

    />
  ) : (
    <>
      <DashboardItemTitle title="Recruitment Channels" />
      <NoData />
    </>
  )

  return component
}

export default memo(RecruitmentFunnelsChart)
