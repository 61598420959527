import React, { useEffect } from 'react'

import styled from 'styled-components'
import { P } from '../../style/Typography.style'

const FilterInput = ({
  title,
  placeholder,
  onChange,
  handleClear,
  value,
  maxW,
  whiteSpace,
}) => {
  return (
    <FilterInputWrapper maxW={maxW}>
      <P whiteSpace={whiteSpace} weight="500" marginRight=".75rem">
        {title}
      </P>

      <input
        onChange={onChange}
        value={value}
        type="text"
        placeholder={placeholder}
      />

      {value !== '' && (
        <P
          onClick={handleClear}
          whiteSpace={whiteSpace}
          weight="500"
          marginRight=".5rem"
          size="0.75rem"
          cursor="pointer"
        >
          X
        </P>
      )}
    </FilterInputWrapper>
  )
}

export default FilterInput

export const FilterCheckBox = ({
  label,
  name,
  onChange,
  onClick,
  mb,
  mr,
  checked,
  size,
  pos,
  top,
  bottom,
  left,
  right,
  justifyContent,
  updateFilterList,
  setUpdateFilter,
  disabled,
}) => {
  useEffect(() => {
    if (updateFilterList) {
      if (document.getElementById(`${name}`)) {
        document.getElementById(`${name}`).checked = false
        setUpdateFilter(false)
      }
    }
  }, [updateFilterList])
  return (
    <FilterCheckBoxWrapper
      size={size}
      pos={pos}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      mb={mb}
      mr={mr}
      justifyContent={justifyContent}
    >
      <input
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        value={label}
        className="check"
        name={label}
        id={name}
        type="checkbox"
      />
      <label htmlFor={name}>{label}</label>
    </FilterCheckBoxWrapper>
  )
}

const FilterInputWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  width: 100%;
  max-width: ${({ maxW }) => maxW || '350px'};
  background: #fff;
  border: 0.5px solid #aeb3c9;
  padding: 0.75rem;
  margin-right: 1rem;

  input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    font-size: 0.75rem;
    font-weight: 300;
    color: #31374f;
    margin-left: 0.1rem;
  }
`

const FilterCheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => (props.mr ? props.mr : '2.1rem')};
  margin-bottom: ${(props) => (props.mb ? props.mb : '0')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
  position: ${({ pos }) => pos || 'static'};
  top: ${({ top }) => top || 'auto'};
  right: ${({ right }) => right || 'auto'};
  left: ${({ left }) => left || 'auto'};
  bottom: ${({ bottom }) => bottom || 'auto'};

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  .check {
    width: ${({ size }) => (size === 'small' ? '1rem' : '1.188rem')};
    height: ${({ size }) => (size === 'small' ? '1rem' : '1.188rem')};
    border: 1px solid #0e1324;
    accent-color: #0e1324;
    border-radius: 0;
  }

  label {
    margin-left: 0.5rem;
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;
  }
`
