import React from 'react'

import styled from 'styled-components'

import { RiSearch2Line } from 'react-icons/ri'

const DashboardButton = ({
  type = 'button',
  notIcon,
  text = '',
  rounded,
  border,
  onClick,
  height,
  maxWidth,
  icon,
  color,
  hoverColor,
  textColor,
  externalIcon,
  float,
  marginRight,
}) => {
  return (
    <DashboardButtonStyle
      height={height}
      maxWidth={maxWidth}
      onClick={onClick}
      type={type}
      border={border}
      rounded={rounded}
      color={color}
      hoverColor={hoverColor}
      textColor={textColor}
      float={float}
      marginRight={marginRight}
    >
      {!notIcon ? icon || <RiSearch2Line className="icon" /> : null}
      {externalIcon && externalIcon}
      {text}
    </DashboardButtonStyle>
  )
}

export default DashboardButton

const DashboardButtonStyle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: ${({ border }) => border || 'none'};
  background: ${({ color }) => color || '#1a1a1a'};
  border-radius: ${({ rounded }) => (rounded ? '100px' : '3px')};
  color: ${({ textColor }) => textColor || '#fff'};
  font-size: 0.875rem;
  font-weight: 500;
  height: ${({ height }) => height || '38px'};
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '121px'};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  float: ${({ float }) => float};
  margin-right: ${({ marginRight }) => marginRight};

  &:hover {
    background: ${({ hoverColor, color }) => hoverColor || color || '#31374f'};
  }

  &:disabled {
    background: #aeb3c9;
  }

  .icon {
    margin-right: 0.5rem;
    font-size: 0.875rem;
    color: #fff;
  }
`
