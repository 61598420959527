import React from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import { GrFormClose } from 'react-icons/gr'
import { IoWarning } from 'react-icons/io5'
import { AiFillInfoCircle } from 'react-icons/ai'
import { ClipLoader } from 'react-spinners'

import { setModal } from '../../../store/actions/listsActions'

const MainModal = ({
  title,
  children,
  buttonText,
  scheduledButtonText,
  previousBtn,
  info,
  error,
  loading,
  scheduedLoading,
  onClick,
  onClickScheduledButton,
  onClose,
  header,
  size = 'small',
}) => {
  const dispatch = useDispatch()
  const { modal } = useSelector((state) => state.lists)

  const handleClose = () => {
    if (onClose) {
      onClose()
    } else {
      if (Array.isArray(modal)) {
        const modals = modal.slice(0, -1)
        dispatch(setModal(modals))
      } else {
        dispatch(setModal(''))
      }
    }
  }

  return ReactDOM.createPortal(
    <Modal
      className={modal === '' ? 'close' : ''}
      size={size}
      previousBtn={previousBtn}
    >
      <div onClick={() => handleClose()} className="modal_overlay" />
      <div className="modal-container">
        {/* modal header */}
        {header ? (
          <>{header}</>
        ) : (
          <>
            <div className="header">
              <div className="modal-title">
                {info && <AiFillInfoCircle className="info_icon" />}
                {error && <IoWarning className="error_icon" />}
                {title}
              </div>
              <div
                onClick={() => handleClose()}
                className="modal-close-wrapper"
              >
                <GrFormClose className="modal-close" />
              </div>
            </div>
          </>
        )}

        {/* modal content */}
        <div className="modal_content">{children}</div>

        {/* modal footer */}
        <div className="modal_footer">
          {previousBtn && (
            <div className="modal_footer_content">
              <button
                onClick={() => previousBtn()}
                className={`button ${
                  buttonText === 'Close' ? 'primary' : 'secondary'
                }`}
              >
                Previous
              </button>
            </div>
          )}
          <div className="modal_footer_content">
            <button
              onClick={() => handleClose()}
              className={`button ${
                buttonText === 'Close' ? 'primary' : 'secondary'
              }`}
            >
              {buttonText === 'Close' ? 'Close' : 'Cancel'}
            </button>
            {buttonText !== 'Close' && (
              <button
                onClick={onClick}
                className={`button primary ${error && 'error'} ${
                  info && 'info'
                }`}
              >
                {loading ? (
                  <ClipLoader color="#fff" size="0.75rem" />
                ) : (
                  buttonText
                )}
              </button>
            )}
            {scheduledButtonText && (
              <button
                onClick={onClickScheduledButton}
                className={`button primary ${error && 'error'} ${
                  info && 'info'
                }`}
                id="scheduledReject"
              >
                {scheduedLoading ? (
                  <ClipLoader color="#fff" size="0.75rem" />
                ) : (
                  scheduledButtonText
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>,
    document.getElementById('modal')
  )
}

export default MainModal

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background: rgba(71, 86, 116, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;

  &.close {
    display: none;
  }

  .modal_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: -1;
  }

  .modal-container {
    width: 100%;
    max-width: 689px;
    min-height: auto;
    height: ${(props) =>
      props.size === 'small' ? 'auto' : 'calc(115% - 165px)'};
    overflow-y: auto;
    background: #fff;
    padding: 1rem 2.5rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;

      .modal-title {
        font-size: 1.5rem;
        font-weight: 300;
        color: #31374f;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .info_icon {
          font-size: 2rem;
          color: #1877f2;
        }

        .error_icon {
          color: #dc2929;
          font-size: 2rem;
        }
      }

      .modal-close-wrapper {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background 0.2s ease-in-out;

        &:hover {
          background: #f5f5f5;
        }

        .modal-close {
          font-size: 1.5rem;
        }
      }
    }

    .modal_content {
      min-height: 100px;
      width: 100%;
    }

    /* footer */
    .modal_footer {
      display: flex;
      align-items: center;
      justify-content: ${(props) =>
        props.previousBtn ? 'space-between' : 'flex-end'};
      margin-top: 1rem;

      .modal_footer_content {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .button {
          padding: 0.5rem 1.5rem;
          font-size: 0.875rem;
          font-weight: 500;
          color: #fff;
          border: none;
          outline: none;
          border-radius: 100px;
          transition: background 0.2s ease-in-out;

          &.primary {
            background: #191919;

            &:hover {
              background: #2f2f2f;
            }
          }

          &.secondary {
            background: none;
            color: #191919;

            &:hover {
              background: #f5f5f5;
            }
          }

          &.error {
            background: #dc2929;
            color: #fff;

            &:hover {
              background: #e45050;
            }
          }

          &.info {
            background: #1877f2;
            color: #fff;

            &:hover {
              background: #4d93ee;
              color: #fff;
            }
          }
        }
      }
    }
  }
`
