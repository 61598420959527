import React, { useEffect, useState, useCallback } from 'react'
import ReactDOM from 'react-dom'

import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'
import { IoCloseOutline } from 'react-icons/io5'
import { BsDownload } from 'react-icons/bs'
import Avatar from 'react-avatar'

import { H6, P } from '../../../style/Typography.style'

import AddNote from './AddNote'
import Activities from './Activities'
import Notes from './Notes'
import POCNotes from './POCNotes'
import Skills from './Skills'
import ApplicantDetails from './ApplicantDetails'
import ReasonForRejection from './ReasonForRejection'
import WorkEthicsToggler from '../WorkEthicsToggler'
import Tags from '../../Tags'
import PageButtons from '../../PageButtons'
import ConfirmLeaveModal from './ConfirmLeaveModal'
import DashboardButton from '../DashboardButton'

import Flex from '../../layouts/Flex'

import axiosInstance from '../../../services/config/AxiosIntance'

import useFetch from '../../../hooks/useFetch'

import { getCVImage, equals, debounce } from '../../../utils/utils'

import {
  CLOSE_ACTIVITIES_MODAL,
  OPEN_ACTIVITIES_MODAL,
  SET_DOWNLOAD_MODAL,
  SET_SHOW_CONFIRM_LEAVE_MODAL,
} from '../../../store/actions/types'

import {
  addCv,
  getTalent,
  setTalent,
} from '../../../store/actions/talentActions'
import { setModal } from '../../../store/actions/listsActions'
import {
  setModalFooter,
  setSelectedInterview,
  setChangedInterviews,
} from '../../../store/actions/recruitersActions'

const ApplicantDetailsModal = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [currentPageNo, SetCurrentPageNo] = useState(1)

  const { talent } = useSelector((state) => state.talents)

  const { data, loading, refetch } = useFetch(
    `/notes?applicant_id=${talent?.id}`
  )

  const { activities, showConfirmLeaveModal } = useSelector(
    (state) => state.modal
  )
  const { modalFooter, selectedStage, applicants, selectedInterview } =
    useSelector((state) => state.requisitions)

  const { modal } = useSelector((state) => state.lists)

  const handleCloseModal = () => {
    const savedSkills = JSON.parse(talent?.recruiter_input_skills)
    const cachedData = localStorage.getItem('cachedData')

    const parsedCachedData = JSON.parse(cachedData) || []
    const skillCache = parsedCachedData.skill || []
    const cachedSkills = skillCache
      ? skillCache.filter((e) => e.applicant_id === talent?.id)
      : []

    if (
      cachedSkills.length > 0 &&
      !equals(savedSkills, cachedSkills[0].skill)
    ) {
      dispatch({ type: SET_SHOW_CONFIRM_LEAVE_MODAL, payload: true })
    } else {
      dispatch({ type: CLOSE_ACTIVITIES_MODAL })
      dispatch(setModal([]))
      dispatch(setModalFooter(false))
    }
  }

  const handlePageClick = (page) => {
    SetCurrentPageNo(page)

    const row = applicants[page - 1]
    dispatch(getTalent(row.applicant_id))
    dispatch(setSelectedInterview(row))
  }
  const handleMarkAsUnread = async () => {
    try {
      const result = await axiosInstance.put(
        `/v2/interview/${talent.id}/read-status`,
        {
          read: false,
        }
      )

      const updatedInterview = { ...selectedInterview }
      updatedInterview.readApplicantId = null

      dispatch(setChangedInterviews([updatedInterview]))

      dispatch(setTalent({ ...talent, readApplicantId: null }))

      addToast(result.data.message, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (error) {
      addToast(error?.message || 'Something went wrong.', {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      handleCloseModal()
    }
  }

  const debouncedUpdateStatus = useCallback(
    // Debounced function call to prevent from being called multiple times due to re-renders
    debounce(() => {
      axiosInstance
        .put(`/v2/interview/${talent.id}/read-status`, {
          read: true,
        })
        .then((result) => {
          const response = result.data.resp

          const updatedInterview = { ...selectedInterview }
          updatedInterview.readApplicantId = response?.applicant_id

          dispatch(setChangedInterviews([updatedInterview]))
        })
    }, 250),
    [talent.id, selectedInterview, dispatch]
  )

  useEffect(() => {
    if (talent?.id || talent?.applicantId) {
      axiosInstance
        .get(`${talent?.id || talent?.applicantId}/uploaded-cv`)
        .then((res) => {
          dispatch(addCv(res.data.resp))
        })
        .catch((err) => {
          if (err.response.data.http_code === 404) {
            dispatch(addCv([]))
          }
        })

      // Update read status
      if (!talent?.readApplicantId) {
        debouncedUpdateStatus()
      }

      // clean up to avoid memory leaks
      return () => {
        debouncedUpdateStatus.cancel && debouncedUpdateStatus.cancel()
      }
    }
  }, [talent])

  useEffect(() => {
    dispatch({
      type: OPEN_ACTIVITIES_MODAL,
      payload: 'activities',
    })
    dispatch({
      type: SET_SHOW_CONFIRM_LEAVE_MODAL,
      payload: false,
    })
  }, [])

  return ReactDOM.createPortal(
    <StyledModalWrapper>
      <div className="modal-wrapper">
        <IoCloseOutline onClick={handleCloseModal} className="icon" />

        <BsDownload
          onClick={() => dispatch({ type: SET_DOWNLOAD_MODAL, payload: true })}
          className="icon second"
        />

        <div className="interview-info">
          <ModalHeader>
            <Avatar
              size="72"
              round={true}
              color="#1A1A1A"
              src={getCVImage(talent)}
              name={talent?.name || 'Talent Name'}
            />

            <div className="details">
              <H6 weight="500" size="1.25rem" align="left" mb="0">
                {talent?.name || 'Talent Name'}
              </H6>
              <P marginTop="0.25rem" size="0.875rem">
                Role: {talent?.role || talent?.listName}
              </P>
              {talent.applicant_status === 'DECLINED' && (
                <Tags status="declined" text="Declined" />
              )}
              <Flex justify="flex-end">
                <DashboardButton
                  text="Mark as unread"
                  type="button"
                  notIcon
                  maxWidth="180px"
                  onClick={handleMarkAsUnread}
                />
              </Flex>
            </div>
          </ModalHeader>
          <ModalBody>
            <WorkEthicsToggler talentId={talent.id} userId={talent.user_id} />
            {talent.applicant_status !== 'DECLINED' ? (
              <AddNote refetch={refetch} />
            ) : (
              <ReasonForRejection talent={talent} />
            )}

            <Flex margin="1rem 0 0.75rem 0" gap="0.75rem" items="center">
              <div
                onClick={() =>
                  dispatch({
                    type: OPEN_ACTIVITIES_MODAL,
                    payload: 'activities',
                  })
                }
                className={
                  activities === 'activities'
                    ? 'talentHeading active'
                    : 'talentHeading'
                }
              >
                Activity
              </div>
              <div
                onClick={() =>
                  dispatch({ type: OPEN_ACTIVITIES_MODAL, payload: 'pocNotes' })
                }
                className={
                  activities === 'pocNotes'
                    ? 'talentHeading active'
                    : 'talentHeading'
                }
              >
                POC Notes
              </div>
              <div
                onClick={() =>
                  dispatch({ type: OPEN_ACTIVITIES_MODAL, payload: 'notes' })
                }
                className={
                  activities === 'notes'
                    ? 'talentHeading active'
                    : 'talentHeading'
                }
              >
                Notes
              </div>
              <div
                onClick={() =>
                  dispatch({ type: OPEN_ACTIVITIES_MODAL, payload: 'skills' })
                }
                className={
                  activities === 'skills'
                    ? 'talentHeading active'
                    : 'talentHeading'
                }
              >
                Skills
              </div>
            </Flex>

            {activities === 'activities' && (
              <Activities id={talent?.id || talent?.applicantId} />
            )}
            {activities === 'pocNotes' && <POCNotes loading={loading} />}
            {activities === 'notes' && (
              <Notes data={data} loading={loading} refetch={refetch} />
            )}
            {activities === 'skills' && <Skills talent={talent} />}
          </ModalBody>
        </div>
        <div className="cv-details">
          <ApplicantDetails talent={talent} />
        </div>
        {modalFooter && selectedStage !== 'closed' && (
          <div className="sticky-footer">
            <Pagination>
              <PageButtons
                totalPages={applicants?.length || 0}
                currentPage={currentPageNo}
                maxWidth={500}
                onPageClick={handlePageClick}
              />
              <span>{`${currentPageNo} of ${applicants?.length}`}</span>
            </Pagination>
            <div className="button-group">
              <button
                onClick={() =>
                  dispatch(setModal([...modal, 'rejectApplicants']))
                }
                className="button warning"
              >
                Reject Applicant
              </button>
              <button
                onClick={() => dispatch(setModal([...modal, 'editSchedule']))}
                className="button success"
              >
                Update Stage
              </button>
            </div>
          </div>
        )}
      </div>
      {showConfirmLeaveModal && <ConfirmLeaveModal />}
    </StyledModalWrapper>,
    document.getElementById('modal')
  )
}

export default ApplicantDetailsModal

const StyledModalWrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(71, 86, 116, 0.4);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 101;

  .modal-wrapper {
    width: 100%;
    max-width: 1336px;
    height: 94vh;
    background: #fff;
    display: grid;
    grid-template-columns: 579px 1fr;
    position: relative;

    .sticky-footer {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 1336px;
      background: #fff;
      box-shadow: 0px -5px 4px rgba(216, 216, 216, 0.25);
      padding: 0.75rem 1.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .button-group {
        display: flex;
        align-items: center;
        gap: 1rem;

        .button {
          font-size: 0.75rem;
          font-weight: 500;
          border-width: 2px;
          border-radius: 3px;
          transition: all 0.2s ease-in-out;
          padding: 0.5rem 1rem;
          background: none;
          outline: none;

          &.warning {
            color: #dc2929;
            border-color: #dc2929;

            &:hover {
              background: #dc2929;
              color: #fff;
            }
          }

          &.success {
            color: #41ba80;
            border-color: #41ba80;

            &:hover {
              background: #41ba80;
              color: #fff;
            }
          }
        }
      }
    }

    .interview-info {
      border-right: 1px solid #dfdfe9;
    }

    .cv-details {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 1.5rem;
    }

    .icon {
      position: absolute;
      top: 2rem;
      right: 2rem;
      cursor: pointer;

      &.second {
        right: 5rem;
      }
    }
  }
`

const ModalHeader = styled.div`
  width: 100%;
  padding: 1.25rem 2rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  border-bottom: 1px solid #dfdfe9;

  .avatar {
    width: 2.5rem;
    height: 2.5rem;
  }

  .details {
    width: 100%;
  }
`

const ModalBody = styled.div`
  width: 100%;
  padding: 1.25rem 2rem;
  height: calc(100vh - 12.5rem);
  overflow-y: auto;

  .talentHeading {
    font-size: 0.875rem;
    font-weight: 300;
    color: #676e92;
    padding: 0.2rem 0;

    &.active,
    &:hover {
      color: #1a1a1a;
      border-bottom: 1px solid #1877f2;
      cursor: pointer;
    }
  }
`

const Pagination = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 80%;

  display: flex;
  align-items: center;

  span {
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;
    margin-left: 0.75rem;
  }
`
