import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import Flex from '../../../../components/layouts/Flex'
import ApplicantScreening from './ApplicantScreening'
import FirstInterview from './FirstInterview'
import SecondInterview from './SecondInterview'
import SkillAssessment from './SkillAssessment'
import ClientProfiling from './ClientProfiling'
import ClientInterview from './ClientInterview'
import Offer from './Offer'
import Closed from './Closed'

import {
  setInterviewStage,
  setSelectedInterview,
  setModalFooter,
  getApplicantIds,
  setBulkItems,
} from '../../../../store/actions/recruitersActions'
import { getTalent } from '../../../../store/actions/talentActions'
import { setModal } from '../../../../store/actions/listsActions'

import interviewPhase from '../../../../constant/interviewPhase'

import axiosInstance from '../../../../services/config/AxiosIntance'

const RequisitionListWrapper = () => {
  const { filters, updatedStage, applicantListUrl } = useSelector(
    (state) => state.requisitions
  )

  const dispatch = useDispatch()
  const [phase, setPhase] = useState(0)
  const [screening, setScreening] = useState('')
  const [firstInterview, setFirstInterview] = useState('')
  const [secondInterview, setSecondInterview] = useState('')
  const [skillAssesment, setSkillAssesment] = useState('')
  const [clientProfiling, setClientProfiling] = useState('')
  const [clientInterview, setClientInterview] = useState('')
  const [offer, setOffer] = useState('')
  const [closed, setClosed] = useState('')

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const userId = JSON.parse(currentUser)?.id
  const recruiterAssistant = !!JSON.parse(currentUser)?.recruiter_assistant

  const companyDetails =
    role === 'poc' && JSON.parse(currentUser)?.companyDetails

  let recordCounts = []

  useEffect(() => {
    getDataWithOutInterviewer()
    getDataWithInterviewer()
  }, [filters])

  useEffect(() => {
    if (updatedStage) {
      getDataWithOutInterviewer()
      getDataWithInterviewer()
    }
  }, [updatedStage])

  const getDataWithOutInterviewer = () => {
    const stages = interviewPhase.map((x) => x.value)

    let url = `/interview-counts?stages=${stages}&searchTerm=${filters.search}&job_title=${filters.searchRole}&vacancy=${filters.vacancy}&startDate=${filters.startDate}&endDate=${filters.endDate}`

    if (!recruiterAssistant && role === 'recruiter') {
      url += `&recruiterId=${userId}`
    } else if (role === 'poc') {
      url += `&companyDetails=${JSON.stringify(companyDetails)}`
    }

    axiosInstance.get(url).then((res) => {
      recordCounts = res?.data?.resp?.interviews

      setScreening(getRecordCount('applicant_screening'))
      setFirstInterview(getRecordCount('first_interview'))
      setSecondInterview(getRecordCount('second_interview'))
      setSkillAssesment(getRecordCount('Skill_assesment'))
      setClientProfiling(getRecordCount('client_profiling'))
      setClientInterview(getRecordCount('client_interview'))
      setOffer(getRecordCount('offer'))
      setClosed(getRecordCount('closed'))
    })
  }

  const getDataWithInterviewer = () => {
    const stages = interviewPhase.map((x) => x.value)

    let url = ''
    if (recruiterAssistant || role === 'admin') {
      url = `/interview-counts?stages=${stages}&searchTerm=${filters.search}&job_title=${filters.searchRole}&vacancy=${filters.vacancy}&startDate=${filters.startDate}&endDate=${filters.endDate}`
    } else if (!recruiterAssistant && role === 'recruiter') {
      url = `/interview-counts?stages=${stages}&recruiterId=${userId}&searchTerm=${filters.search}&job_title=${filters.searchRole}&vacancy=${filters.vacancy}&startDate=${filters.startDate}&endDate=${filters.endDate}`
    } else if (role === 'interviewer') {
      if (!url) {
        url = `/interview-counts?stages=${stages}&interviewerId=${
          JSON.parse(currentUser)?.interviewerId
        }&searchTerm=${filters.search}&job_title=${
          filters.searchRole
        }&vacancy=${filters.vacancy}&startDate=${filters.startDate}&endDate=${filters.endDate}`
      }
    }
    axiosInstance.get(url).then((res) => {
      recordCounts = res?.data?.resp?.interviews
      setSecondInterview(getRecordCount('second_interview'))
      setClientInterview(getRecordCount('client_interview'))
    })
  }

  const getRecordCount = (stage) => {
    try {
      if (recordCounts.length > 0) {
        const cnt = recordCounts.filter((x) => x.stage === stage)[0].count
        return `(${cnt})`
      } else {
        return ''
      }
    } catch (e) {
      return ''
    }
  }

  const handleRowClick = (row) => {
    dispatch(getApplicantIds(applicantListUrl))
    dispatch(getTalent(row.applicant_id))
    dispatch(setModalFooter(true))
    dispatch(setSelectedInterview(row))
    dispatch(setModal(['applicantDetails']))
  }

  const handleStageClick = (stage, index) => {
    displayStage(stage.code, index)

    dispatch(
      setBulkItems({
        stage: stage.code,
        filters: {
          searchTerm: decodeURIComponent(filters.search),
          job_title: decodeURIComponent(filters.searchRole),
          startDate: new Date(decodeURIComponent(filters.startDate)),
          endDate: new Date(decodeURIComponent(filters.endDate)),
          vacancy: decodeURIComponent(filters.vacancy),
        },
      })
    )
  }

  const stages = [
    {
      name: 'Screening',
      code: 'applicant_screening',
      search_count: screening,
      component: <ApplicantScreening onRowClick={handleRowClick} />,
    },
    {
      name: '1st Interview',
      code: 'first_interview',
      search_count: firstInterview,
      component: <FirstInterview onRowClick={handleRowClick} />,
    },
    {
      name: '2nd Interview',
      code: 'second_interview',
      search_count: secondInterview,
      component: <SecondInterview onRowClick={handleRowClick} />,
    },
    {
      name: 'Skill Assessment',
      code: 'Skill_assesment',
      search_count: skillAssesment,
      component: <SkillAssessment onRowClick={handleRowClick} />,
    },
    {
      name: 'Client Profiling',
      code: 'client_profiling',
      search_count: clientProfiling,
      component: <ClientProfiling onRowClick={handleRowClick} />,
    },
    {
      name: 'Client Interview',
      code: 'client_interview',
      search_count: clientInterview,
      component: <ClientInterview onRowClick={handleRowClick} />,
    },
    {
      name: 'Offer',
      code: 'offer',
      search_count: offer,
      component: <Offer onRowClick={handleRowClick} />,
    },
    {
      name: 'Closed',
      code: 'closed',
      search_count: closed,
      component: <Closed onRowClick={handleRowClick} />,
    },
  ]

  const displayStage = (code, number) => {
    dispatch(setInterviewStage(code))
    setPhase(number)
  }

  return (
    <StyledRequisitionListWrapper>
      <Flex padding="0.75rem 1rem" items="center" gap="2.5rem">
        {stages.map((stage, index) => (
          <div
            key={index}
            onClick={() => handleStageClick(stage, index)}
            className={phase === index ? 'tab active' : 'tab'}
          >
            {stage.name}&nbsp;{stage.search_count}
          </div>
        ))}
      </Flex>

      {/* Display stages table based on selected stage */}
      {stages[phase].component}
    </StyledRequisitionListWrapper>
  )
}

export default RequisitionListWrapper

const StyledRequisitionListWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 16rem);
  background: #fff;
  border: 0.5px solid #aeb3c9;
  border-bottom: none;
  margin-top: 1rem;
  overflow-y: hidden;

  .tab {
    font-size: 1rem;
    font-weight: 300;
    color: #676e92;
    cursor: pointer;

    &:hover,
    &.active {
      font-weight: 400;
      color: #31374f;
      border-bottom: 1px solid #1877f2;
    }
  }
`
