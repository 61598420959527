import axios from 'axios'
import { baseUrl } from '../../constant/constant'
import { store } from '../../store/store'

const axiosInstance = axios.create({
  baseURL: baseUrl,
  responseType: 'json',
})

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState()
    const token = state.auth.token

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    if (
      error.response.status === 401 &&
      error.response.data === 'Unauthorized'
    ) {
      localStorage.removeItem('recruiterToken')
      window.location.href = '/login'
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

export default axiosInstance
